import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/user/user';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HeaderService } from 'src/app/header/header.service';
import { LogoutService } from 'src/app/services/logout/logout.service';

@Component({
  selector: 'app-header-sidenav-my-account',
  templateUrl: './header-sidenav-my-account.component.html',
  styleUrls: ['./header-sidenav-my-account.component.scss']
})
export class HeaderSidenavMyAccountComponent implements OnInit {
  @Input() user: User = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[]
  } as User;
  profileMenuDefaultMsg: string;
  profileMenuNameMsg: string;
  profileMenuMessage: string;
  profilefullCompanyName: string;
  profileEmailAddress: string;
  @Input() yourAccountLink = '';
  logoutConfirmed = false;
  constructor( public matDialog: MatDialog,
               public snackBar: MatSnackBar,
               public headerService: HeaderService,
               public logoutService: LogoutService
   ) { }

  ngOnInit(): void {
    this.setupProfileMenuMessage();
  }
  setupProfileMenuMessage() {
    this.profileMenuMessage = (this.profileMenuDefaultMsg) ? this.profileMenuDefaultMsg : 'Hello!';
    this.profileEmailAddress = '';
    if (this.user && this.user.fullName) {
      this.profileMenuMessage = this.user.fullName;
      this.profileEmailAddress = this.user.emailAddress;
      this.profilefullCompanyName = this.user.formalCompanyName;
    } else if (this.user && this.user.firstName) {
      this.profileMenuMessage = this.user.firstName;
      this.profileEmailAddress = this.user.emailAddress;
      this.profilefullCompanyName = this.user.formalCompanyName;
    }
  }
  openLogoutModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      type: 'error',
      title: 'Are you sure you would like to logout?',
      actions: [{ func: 'closeModal', text: 'No' }, { func: 'actionFunction', text: 'Yes' }]
    };
    const logoutDialog = this.matDialog.open(ConfirmationModalComponent, dialogConfig);
    logoutDialog.afterClosed().subscribe(logoutConfirmed => {
      this.logoutConfirmed = logoutConfirmed;
      if (this.logoutConfirmed) {
        this.snackBar.dismiss();
        this.headerService.setLogoutClicked(true);
        this.logoutUser();
      }
    });
  }
  private logoutUser(): void {
    this.logoutService.logout(true);
  }
}
