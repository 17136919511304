import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { AppConfigService } from 'src/app/core/app-config.service';
import { FooterData } from 'src/app/footer/footer-data';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor(private appConfigService: AppConfigService) { }

  public getFooterData(oemPlatformAcronym: string): Observable <FooterData>  {
    return oemPlatformAcronym
            ? this.appConfigService.fetchComponentConfig(`footer-data-${oemPlatformAcronym.toLowerCase()}`)
            : EMPTY;
  }

  public getNxgGenFooterData(oemPlatformAcronym: string): Observable <FooterData>  {
    return oemPlatformAcronym
            ? this.appConfigService.fetchComponentConfig(`nxg-footer-data`)
            : EMPTY;
  }
}
