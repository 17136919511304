import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { HeaderService } from 'src/app/header/header.service';
import { UserConfig, UserConfigData } from 'src/app/header/header-interface';
import { UserApiService } from 'src/app/user/user-api.service';

@Component({
  selector: 'app-home-page-config-options',
  templateUrl: './home-page-config-options.component.html',
  styleUrls: ['./home-page-config-options.component.scss']
})
export class HomePageConfigOptionsComponent implements OnInit {
  @Input() isEnableSubMenu: boolean;
  isMobile = false;
  @Output() isSubMenuSelected = new EventEmitter<boolean>();

  userConfig: UserConfig[] = [];
  userConfigData: UserConfigData[] = [];
  homePageConfig: UserConfig[] = [];
  user: any;


  constructor(public headerService: HeaderService,
              public userApiService: UserApiService,
) { }

  ngOnInit(): void {
    this.userApiService.getUser().subscribe((data) => {
      this.user = data;
    });

    this.headerService.getUserConfig().subscribe((configs) => {
      if (configs.body !== null) {
        this.userConfig = configs.body;
        this.homePageConfig = configs.body;
        this.populateConfigs();
      }
    });

  }
  populateConfigs() {
    this.headerService.getUserConfigData().subscribe((configs) => {
      if (configs.body !== null) {
         this.userConfigData = configs.body;
         this.userConfigData.forEach((existingConfig) => {
          if (existingConfig.configName === 'My Dashboard') {
            this.headerService.setDashboardChecked(true);
            this.updateCheckBox(existingConfig.configName);
          } else if (existingConfig.configName === 'My Favorite') {
            this.headerService.setFavoritesChecked(true);
            this.updateCheckBox(existingConfig.configName);
          }
          this.isSubMenuSelected.emit(true);
         });
      }
    });
  }
  updateCheckBox(optionName){
    this.homePageConfig.forEach((homeconfig) => {
      if (homeconfig.configName === optionName) {
          homeconfig.selected = true;
      }
    });
  }
  checkCheckBoxvalue(event, option) {
    if (option === 'My Dashboard') {
       if (event.checked) {
        const isConfigExist = this.userConfigData.some((configs) => configs.configName === option);
        if (!isConfigExist) {
           this.updateUserConfigData(option);
        }
        this.headerService.setDashboardChecked(event.checked);
       } else if (!event.checked) {
        this.deleteUserConfigData(option);
        this.headerService.setDashboardChecked(false);
       }
    } else if (option === 'My Favorite') {
      if (event.checked) {
        const isConfigExist = this.userConfigData.some((configs) => configs.configName === option);
        if (!isConfigExist) {
           this.updateUserConfigData(option);
        }
        this.headerService.setFavoritesChecked(event.checked);
       } else if (!event.checked) {
        this.deleteUserConfigData(option);
        this.headerService.setFavoritesChecked(false);
       }
    }
  }
  updateUserConfigData(configOption) {
    const configData: any = {};
    configData.id = '';
    this.userConfig.forEach((userConfig) => {
      if (userConfig.configName === configOption) {
        configData.configMenuId = userConfig.id;
        configData.userId = this.user.userId;
        this.headerService.setUserConfigData(configData).subscribe((result) => {
          if (result !== null) {
            this.populateConfigs();
          }
        });
      }
    });
  }
  deleteUserConfigData(configOption) {
    const configData: any = {};
    this.userConfigData.forEach((userConfig) => {
      if (userConfig.configName === configOption) {
        configData.id = userConfig.id;
        configData.configMenuId = userConfig.configMenuId;
        configData.userId = this.user.userId;

        this.headerService.deleteUserConfigData(configData).subscribe((result) => {
          if (result !== null) {
            this.populateConfigs();
          }
        });
      }
    });
  }


}
