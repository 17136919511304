<div *ngIf="checkMobile()">
    <app-header-mbf-sidenav-mobile-tab [currentTab]="currentTab" [envConfig]="envConfig" [user]="user" [yourAccountLink]="yourAccountLink" [workspaceLocked]="workspaceLocked" [communitiesItems]="communitiesItems" [accessAdministratorFlag]="accessAdministratorFlag"></app-header-mbf-sidenav-mobile-tab>
</div>
<div *ngIf="!checkMobile()" class="display_side_Menu colorNav">
    <div class="py-zoom" id="myBoeingFleet-pages-target">
        <div *ngFor="let item of secondaryBannerLeft" class="justify-between h-full items-center">
            <div *ngIf="item | headerAdminLink:user:envConfig"
                class="secnd-banner-applications-{{envConfig['oemPlatformAcronym']}} mx-2 py-44">
                <span style="color:#4E4E4E" class="header-side-menu-subtitle pl-8">
                    <mat-icon class="nav-icon-style" *ngIf="item.title!='ADMIN' && item.title !== 'APPLICATIONS'">
                        <span class="material-symbols-sharp text-px-24">{{item.icon}}</span>
                    </mat-icon>
                    <mat-icon class="nav-icon-style" *ngIf="item.title=='ADMIN'">
                        <span class="material-symbols-sharp text-px-24">admin_panel_settings</span>
                    </mat-icon>
                    <mat-icon class="nav-icon-style" *ngIf="item.title=='APPLICATIONS'">
                        <span class="material-symbols-sharp text-px-24">web</span>
                    </mat-icon>
                    <a class="acustom" *ngIf="IfAdminLink(item) && item.title!='APIs'" (click)="closeSideMenu()"
                        [href]="memoizeGetBuildUrl | headerBuildUrl:item"
                        [ngClass]="{'font-bold': item.title == currentTab }"
                        [analyticTagging]="{'eventId':item.analytics?item.analytics.eventId:'','srcCtrl':item.analytics?item.analytics.srcCtrl:''}"
                        target="{{item.target}}">{{item.title | uppercase }}</a>
                    <a class="acustom" *ngIf="IfAdminLink(item) && item.title=='APIs'" (click)="closeSideMenu()"
                        [href]="memoizeGetBuildUrl | headerBuildUrl:item"
                        [ngClass]="{'font-bold': item.title == currentTab }"
                        [analyticTagging]="{'eventId':item.analytics?item.analytics.eventId:'','srcCtrl':item.analytics?item.analytics.srcCtrl:''}"
                        target="{{item.target}}">DEVELOPER TOOLS</a>
                </span>
                <mat-divider class="mt-22"></mat-divider>
            </div>
        </div>
    </div>
</div>
