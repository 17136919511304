<div class="max-w-full">
    <div class="flex-row flex-wrap pl-12">
        <div class="account-name pt-6 pb-2">{{profileMenuMessage}}</div>
        <div class="account-company-name pb-2">{{profilefullCompanyName}}</div>
        <a class="account-my-acc pb-8" href={{yourAccountLink}} target="_blank"
            [analyticTagging]="{'eventId':'globalNavAccountDropDownFn','srcCtrl':'Your Account'}">
            My Account
        </a>
    </div>
</div>
<div class="max-w-full account-logout-container mt-8">
  <a (click)="openLogoutModal()" class="flex flex-row items-center"
    [analyticTagging]="{'eventId':'globalNavAccountDropDownFn','srcCtrl':'LOG OUT'}">
    <span class="material-symbols-sharp account-logout-icon">logout</span>
    <span class="account-logout-label">Log Out</span>
  </a>
</div>
