import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog} from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/app-config.service';
import { HeaderData, HeaderMenu, HeaderSubmenu } from 'src/app//header/header-data';
import { HeaderService } from 'src/app/header/header.service';
import { UserApiService } from 'src/app/user/user-api.service';
import { User } from 'src/app/user/user';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { NavigationEnd, Router } from '@angular/router';
import { memoize } from 'lodash';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
import { Workspace } from 'src/app/workspace/workspace';
import { PollingTimerService } from 'src/app/services/shared/polling-timer/polling-timer.service';
import { PollingItem } from 'src/app/services/shared/polling-timer/polling-interface';
import { SearchResultsService } from 'src/app/services/search-results/search-results.service';
import { LogoutService } from 'src/app/services/logout/logout.service';
import { NxgHeaderSidenavService } from 'src/app/header-mbf-sidenav/nxg-header-sidenav/nxg-header-sidenav.service';

@Component({
  selector: 'app-nxg-header-sidenav',
  templateUrl: './nxg-header-sidenav.component.html',
  styleUrls: ['./nxg-header-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NxgHeaderSidenavComponent implements OnInit {
  @ViewChild('notificationTrigger') notificationTrigger: MatMenuTrigger;
  @ViewChild('accountTrigger') accountTrigger: MatMenuTrigger;
  @ViewChild('linkTrigger') linkTrigger: MatMenuTrigger;
  envConfig = {};
  communityBase: string;
  headerData: HeaderData;
  primaryMenu = [] as HeaderMenu[];
  secondaryMenu: HeaderMenu[] = [] as HeaderMenu[];
  secondaryBannerLeft = [] as HeaderSubmenu[];
  communitiesItems = [] as HeaderSubmenu[];
  currentTab = '';
  dismissJobRoleSurvey = false;
  accessAdministratorFlag = false;
  yourAccountLink = '';
  user: User = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[]
  } as User;
  mbfHome = false;
  showSnackbar = true;
  isRefresh = false;
  snackBarDismiss = true;
  workspaceLocked = false;
  isMobile = false;
  pollingItems: PollingItem[] = [{ key: 'notifications', callback: () => { this.refreshUserAndWorkspaces(); } }];
  memoizeGetBuildUrl = memoize((item: HeaderSubmenu) => this.buildUrl(item), (...args) => args[0].title);

  constructor(
    public cd: ChangeDetectorRef,
    public headerService: HeaderService,
    public appConfigService: AppConfigService,
    public userApiService: UserApiService,
    public workspaceService: WorkspaceService,
    public router: Router,
    public snackBar: MatSnackBar,
    public location: Location,
    public matDialog: MatDialog,
    private searchResultsService: SearchResultsService,
    private pollingTimerService: PollingTimerService,
    private zone: NgZone,
    public logoutService: LogoutService,
    public headerSidenavService: NxgHeaderSidenavService,
  ) { }

  ngOnInit(): void {
    this.communityBase = '#/Platform/workspace/';
    this.appConfigService.envConfig.pipe(
      tap(config => { this.envConfig = config; }),
      switchMap(config => this.headerService.getHeaderData(config[`oemPlatformAcronym`]))
    ).subscribe((response: HeaderData) => {
      if (response) {
        this.headerData = response;
        this.primaryMenu = response.primaryMenu;
        this.secondaryMenu = response.secondaryMenu;
        this.secondaryBannerLeft = this.headerData.secondaryBannerLeft
          .filter(item => !(item.title === `APIs` && this.envConfig[`airlineCode`] !== `TBC`));
      }
      this.cd.markForCheck();
    });
    this.userApiService.getUser().subscribe((user: User) => {
      this.user = user;
    });
    this.checkAccessAdminiPriviledge();
    this.getYourAccountLinks();
    this.setMbfHome();
    this.router.events.subscribe(val => {
      this.setMbfHome();
      this.cd.markForCheck();
    });
    const workspaceList = this.workspaceService.getWorkspacesProp(true);
    workspaceList.subscribe(workspaces => {
      if (workspaces.data && Array.isArray(workspaces.data)) {
        this.communitiesItems = workspaces.data.filter((workspace: Workspace) => workspace.unlisted === false)
          .map((workspace: Workspace) => this.buildWorkspaceLink(workspace));
      }
    });
    this.refreshUserAndWorkspace();
    this.pollingTimerService.registerPolling(this.pollingItems);
    this.setCurrentPage(this.router.url);
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setCurrentPage(this.router.url);
      }
    });
  }
  refreshUserAndWorkspace(): void {
    this.userApiService.getUserAndWorkspace().subscribe((user: User) => {
      this.user = user;
      this.cd.markForCheck();
    });
  }
  checkMobile() {
    if (window.innerWidth <= 991) {
      if (this.isMobile !== true) {
        this.zone.run(() => {
          this.isMobile = true;
        });
      }
      return true;
    } else {
      if (this.isMobile !== false) {
        this.zone.run(() => {
          this.isMobile = false;
        });
      }
      return false;
    }
  }

  refreshUserAndWorkspaces(): void {
    this.userApiService.getUserAndWorkspace().subscribe((user: User) => {
      this.user = user;
      this.showSnackbar = true;
      this.snackBar.dismiss();
      this.headerService.setNotificationsUpdated(true);
      this.cd.markForCheck();
    });
  }

  buildWorkspaceLink(workspace: Workspace) {
    return {
      title: workspace.longName,
      url: `${this.communityBase}${workspace.id}`,
      urlTarget: '', type: '',
      shortName: workspace.shortName
    };
  }
/*** Hide the activated menu options and show the default options
   */
closeSideMenu(): void {
  this.headerService.setopenSideMenu(false);
  this.headerService.setopenNotificationData(false);
  this.cd.markForCheck();
}

/**
 * On click of link icon Close the account menu
 */
closeAccountMenu() {
  if (this.accountTrigger.menuOpen) {
    this.accountTrigger.closeMenu();
    this.cd.markForCheck();
  }
}
closeLinkMenu() {
  if (this.linkTrigger.menuOpen) {
    this.linkTrigger.closeMenu();
    this.cd.markForCheck();
  }
}
setCurrentPage(url: string) {
  if (url === '/' || url.indexOf('Platform/workspace') !== -1) {
    this.currentTab = 'MBF Home';
  }
  else if (url.indexOf('/Admin') !== -1) {
    this.currentTab = 'Admin';
  } else if (url.indexOf('Platform/apps') !== -1) {
    this.currentTab = 'Applications';
  } else if (url.indexOf('YourAccount') !== -1) {
    this.currentTab = 'YourAccount';
  } else {
    this.currentTab = '';
  }
  if (url.indexOf('Platform/jobRoleSurvey') !== -1) {
    this.dismissJobRoleSurvey = true;
  } else {
    this.dismissJobRoleSurvey = false;
  }
}

IfAdminLink(item: HeaderSubmenu) {
  return this.showAdminLinkfromString(item.title);
}

showAdminLinkfromString(title: string = '') {
  if (title.toLowerCase() === 'admin') {
    if (this.user.userRoles.length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

buildUrl(item: HeaderSubmenu) {
  return this.headerSidenavService.buildUrl(item);
}
buildUrlFromString(url: string = '', type: string) {
  return this.headerService.buildUrlFromString(url, type, this.envConfig);
}

setMbfHome() {
  this.mbfHome = (this.location.path() === ''
    || this.location.path() === this.appConfigService.getRoute(`mbfHome`)
    || this.location.path() === this.appConfigService.getRoute(`mbfHomeWorkspace`)
  );
}
checkAccessAdminiPriviledge() {
  let envConfigAccessAdmin = '';
  const environmentString = 'environment';
  const accessAdminUrl = 'accessAdminUrl';
  this.appConfigService.envConfig.subscribe(config => {
   if (config[accessAdminUrl]) {
     this.envConfig = config;
     envConfigAccessAdmin = config[accessAdminUrl];
     if (envConfigAccessAdmin === '') {
       this.accessAdministratorFlag = false;
     }
     else {
       this.accessAdministratorFlag = true;
     }
   }
   else {
     this.accessAdministratorFlag = false;
   }
 });
}
getYourAccountLinks() {
  let env: string = "prod";
  this.yourAccountLink = 'https://accounts.boeing.com/accountSettings?location=Fedp';
  const environmentString = 'environment';
  this.appConfigService.envConfig.subscribe(config => {
    if (config[environmentString]) {
      env = config[environmentString];
    }

    this.appConfigService.fetchComponentConfig('your-account-links').subscribe((result: any) => {
      const envLink = result.yourAccountLinks;
      this.yourAccountLink = envLink[env] ? envLink[env] : this.yourAccountLink;
    });
  });
}
}
