<div>
  <div class="skinny-footer-divider"></div>
  <div class="flex flex-wrap">
    <div class="hidden sm:flex w-full skinny-footer-desktop-tablet">
      <div class="flex text-left justify-start w-1/2">
        <span class="skinny-footer-content">Copyright © {{currYear}} Boeing. All rights reserved.</span>
        <div *ngIf="isEligibleUser && isScreenEligibleUser" class="skinny-footer-build-info-icon"
          (click)="toggleCodeOff();">
          <span *ngIf="!isCodeOff" class="material-symbols-sharp">expand_all</span>
          <span *ngIf="isCodeOff" class="material-symbols-sharp">collapse_all</span>
        </div>
      </div>
      <div class="flex text-right justify-end w-1/2">
        <div class="skinny-footer-content skinny-footer-content-pr">
          <a class="cursor-pointer" (click)="getURL(termsofUselink.url,termsofUselink.urlTarget,termsofUselink)">
            {{termsofUselink.title}}
          </a>
        </div>
        <div class="skinny-footer-content-pr">
          <div class="skinny-footer-content-separator"></div>
        </div>
        <div class="skinny-footer-content">
          <a class="cursor-pointer" (click)="getURL(privacyPolicyCookielink.url,privacyPolicyCookielink.urlTarget,privacyPolicyCookielink)">
            {{privacyPolicyCookielink.title}}
          </a>
        </div>
      </div>
    </div>

    <div class="sm:hidden w-full skinny-footer-mobile">
      <div class="flex justify-center w-full skinny-footer-content-pb">
        <span class="skinny-footer-content">Copyright © {{currYear}} Boeing. All rights reserved.</span>
      </div>
      <div class="flex justify-center w-full">
        <div class="skinny-footer-content skinny-footer-content-prm">
          <a class="cursor-pointer" (click)="getURL(termsofUselink.url,termsofUselink.urlTarget,termsofUselink)">
            {{termsofUselink.title}}
          </a>
        </div>
        <div class="skinny-footer-content-prm">
          <div class="skinny-footer-content-separator"></div>
        </div>
        <div class="skinny-footer-content">
          <a class="cursor-pointer" (click)="getURL(privacyPolicyCookielink.url,privacyPolicyCookielink.urlTarget,privacyPolicyCookielink)">
            {{privacyPolicyCookielink.title}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <app-nxg-skinny-footer-build-info *ngIf="isCodeOff"></app-nxg-skinny-footer-build-info>
</div>
