import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class EventCatelogService {
  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }

  public addEventData(eventData: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const enableEventTracking = this.appConfigService.getProperty('manageEventTracking');
    const endpoint = this.appConfigService.getBackendPath('eventCatelogPost');
    if (enableEventTracking && enableEventTracking !== '') {
      return this.httpClient.post<any>(endpoint, JSON.stringify(eventData), httpOptions);
    }
  }

  getEventData(noOfMonths: number): Observable<any> {
    let endpoint = this.appConfigService.getBackendPath('getEventData');
    endpoint = endpoint + '/' + noOfMonths;
    return this.httpClient.get<any>(endpoint, { observe: 'response' });
  }

  getUserData(): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('getUsersData');
    return this.httpClient.get<any>(endpoint, { observe: 'response' });
  }

}
