<div class="side-nav-wrapper">
    <div class="justify-items-center align-searchBox">
        <app-mbf-search class="pl-8 ml-8 mr-8 showorhide side-nav-wrapper" [viewSearchBar]="false" [showSearch]="true">
    </app-mbf-search>
    <mat-divider></mat-divider>
    </div>
    <div>
        <div class="flex flex-row pt-4 pb-4 pl-2 pr-4 justify-between">
            <ng-container *ngIf="this.currentTab === 'MBF Home' ; else otherPages">
                <div *ngFor="let item of navigationMenu">
                    <ng-container *ngIf="item.title !== 'Notifications'; else notificationMenu"> 
                            <button mat-icon-button (click)="selectNavigation(item)">
                                <mat-icon [ngClass]="{'selected-icon': item.title === selectedItem.title ,'navigation-icons': item.title !== selectedItem.title }">
                                    <span class="material-symbols-sharp text-px-24">{{item.icon}}</span>
                                </mat-icon>
                            </button>
                    </ng-container>
                    <ng-template #notificationMenu>
                            <button mat-icon-button (click)="selectNavigation(item)" [analyticTagging]="{'eventId':'secondaryBannerNotificationIconFn','srcCtrl':this}" class="flex items-center" [ngClass]="{'pointer-events-none': undismissedNotificationsAvailable}">
                                <mat-icon [ngClass]="{'selected-icon': item.title === selectedItem.title ,'navigation-icons': item.title !== selectedItem.title }" [matBadge]=unreadNotifications
                                matBadgeColor="warn" matBadgeSize="small" [matBadgeHidden]="!unreadNotificationsAvailable">
                                    <span class="material-symbols-sharp text-px-24">{{item.icon}}</span>
                                </mat-icon>
                            </button>
                    </ng-template>
                </div>
            </ng-container>
            <ng-template #otherPages>
                <div *ngFor="let item of navMenu">
                    <ng-container *ngIf="item.title !== 'Notifications'; else notificationMenu"> 
                            <button mat-icon-button (click)="selectNavigation(item)">
                                <mat-icon [ngClass]="{'selected-icon': item.title === selectedItem.title ,'navigation-icons': item.title !== selectedItem.title }">
                                    <span class="material-symbols-sharp text-px-24">{{item.icon}}</span>
                                </mat-icon>
                            </button>
                    </ng-container>
                    <ng-template #notificationMenu>
                            <button mat-icon-button (click)="selectNavigation(item)" [analyticTagging]="{'eventId':'secondaryBannerNotificationIconFn','srcCtrl':this}" class="flex items-center" [ngClass]="{'pointer-events-none': undismissedNotificationsAvailable}">
                                <mat-icon [ngClass]="{'selected-icon': item.title === selectedItem.title ,'navigation-icons': item.title !== selectedItem.title }" [matBadge]=unreadNotifications
                                matBadgeColor="warn" matBadgeSize="small" [matBadgeHidden]="!unreadNotificationsAvailable">
                                    <span class="material-symbols-sharp text-px-24">{{item.icon}}</span>
                                </mat-icon>
                            </button>
                    </ng-template>
                </div>
            </ng-template>
            
        </div>
        <mat-divider></mat-divider>
    </div>
    <div class="flex flex-col nav-wrapper" *ngIf="displayNavigationTitle">
        <div class="flex flex-row items-center back-arrow-wrapper nav-title-padding">
           <button mat-icon-button (click)="navigateToDefaultMenu()" class="ml-3">
                <mat-icon class="back-arrow">arrow_back</mat-icon>
            </button>
            <div class="menu-title font-bold text-px-14 uppercase pl-2">{{selectedItem.title}}</div>
        </div>
        <mat-divider></mat-divider>
    </div>
    <div class="flex flex-col mt-6 mb-10" *ngIf="!displayNavigationTitle">
        <div *ngFor="let item of defaultMenu">
            <div *ngIf="item | headerAdminLink:user:envConfig" class="flex flex-col items-baseline mt-3 mb-3 ml-5">
                <div class="flex flex-row items-center">
                    <mat-icon class="navigation-icons">
                        <span class="material-symbols-sharp text-px-24">{{item.icon}}</span>
                    </mat-icon>
                    <a class="menu-title font-medium text-px-12 ml-5" *ngIf="ifAdminLink(item) && item.title!='APIs'" [href]="memoizeGetBuildUrl | headerBuildUrl:item" target="{{item.target}}" (click)="closeSideMenu()" [analyticTagging]="{'eventId':item.analytics?item.analytics.eventId:'','srcCtrl':item.analytics?item.analytics.srcCtrl:''}">{{item.title | uppercase}}</a>
                    <a class="menu-title font-medium text-px-12 ml-5" *ngIf="ifAdminLink(item) && item.title=='APIs'"
                            [href]="memoizeGetBuildUrl | headerBuildUrl:item"
                            [analyticTagging]="{'eventId':item.analytics?item.analytics.eventId:'','srcCtrl':item.analytics?item.analytics.srcCtrl:''}"
                            target="{{item.target}}" (click)="closeSideMenu()">DEVELOPER TOOLS</a>
                                    <mat-icon class="navigation-icons arrow-icon pl-2" *ngIf="ifAdminLink(item) && (item.title=='Configure Home Page')" (click)="enablesuperSubMenu()">
                                        <span *ngIf="!isEnableSubMenu" class="material-symbols-outlined">
                                            arrow_drop_down
                                        </span>
                                        <span *ngIf="isEnableSubMenu" class="material-symbols-outlined">
                                            arrow_drop_up
                                        </span>
                                    </mat-icon>
                </div>
                    
                                    <div *ngIf="(item.title=='Configure Home Page') && isEnableSubMenu">
                                        <app-home-page-config-options [isEnableSubMenu]="isEnableSubMenu" (isSubMenuSelected)="isSubMenuClicked($event)"></app-home-page-config-options>
                                    </div>
            </div>
        </div>
        <!-- <div class="divider-wrapper">
            <mat-divider></mat-divider>
        </div> -->
       
    </div>
    <div *ngIf="displayNavigationTitle" [ngClass]="{'pb-12': selectedItem.title !== 'My Account' && selectedItem.title !== 'Notifications' ,'pb-0': selectedItem.title === 'My Account' || selectedItem.title === 'Notifications'}">
        <div *ngIf="selectedItem.title !== 'Notifications' && selectedItem.title !== 'My Account'">
            <div *ngFor="let item of selectedItem.submenu" >
                <ng-container *ngIf="selectedItem.title === 'Help' ; else otherMenu" >
                    <div *ngIf = "displayItem(item)">
                        <div class="pt-6 pl-12">
                            <a *ngIf="(item.title === 'My Field Service Office' || item.title === 'Access Administrator Support')" class="menu-title font-medium text-px-14 cursor-pointer" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}"
                            (click)="closeSideMenu()" [target]="item.target" [href]="buildUrl(item)">
                                {{item.title}}
                        </a>
                        <a *ngIf="item.title == 'Tutorial'" class="menu-title font-medium text-px-14 cursor-pointer" [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}"
                        (click)="openTutorialDialog('Tutorial')">
                                {{item.title}}
                        </a>
                        <a *ngIf="item.title != 'Tutorial' && item.title != 'My Field Service Office' && item.title != 'Access Administrator Support'"
                            [analyticTagging]="{'eventId':item.analytics.eventId,'srcCtrl':item.analytics.srcCtrl}"
                            [href]="memoizeGetBuildUrl | headerBuildUrl:item" (click)="closeSideMenu()"
                            [target]="item.target" class="menu-title font-medium text-px-14 cursor-pointer">{{item.title}}
                        </a>
                        </div>
                    </div>
                </ng-container>
                <ng-template #otherMenu >
                    <div class="pt-6 pl-12">
                        <a [ngClass]="{'uppercase submenu-title': selectedItem.title !== 'Help','menu-title font-medium text-px-14 cursor-pointer': selectedItem.title === 'Help' }">
                            {{item.title}}
                    </a>
                        <ng-container *ngIf="item.title === 'MBF Home';else otherCategories">
                            <ng-container *ngIf="!workspaceLocked">
                                <div *ngFor="let menuItem of item.submenu; let last = last" class="pl-4 pt-4">
                                    <a (click)="openDashboardDialog(menuItem)" [analyticTagging]="{'eventId':menuItem.analytics.eventId,'srcCtrl':menuItem.analytics.srcCtrl}" class="menu-title font-medium text-px-14 cursor-pointer" [ngClass]="{'pb-4': last}">
                                        {{menuItem.title}}
                                </a>
                            </div>
                            </ng-container>
                            <ng-container *ngIf="workspaceLocked">
                                <div *ngFor="let menuItem of item.submenu; let last = last" class="pl-4 pt-4" matTooltipPosition="above"
                                    [matTooltipDisabled]="menuItem.title == 'Reset home page to default'"
                                    matTooltip="Workspace does not support function. Please change your workspace and try again.">
                                    <a mat-menu-item
                                        [ngClass]="{'dashboard-disable':(menuItem.title != 'Reset home page to default')}"
                                        (click)="(menuItem.title == 'Reset home page to default') && openDashboardDialog(menuItem)"
                                        [disabled]="menuItem.title != 'Reset home page to default'"
                                        [analyticTagging]="{'eventId':menuItem.analytics.eventId,'srcCtrl':menuItem.analytics.srcCtrl}">{{menuItem.title}}
                                    </a>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #otherCategories>
                            <ng-container *ngIf="item.title === 'Communities' && communitiesItems.length > 0 ;else otherSubCategories">
                                <div *ngFor="let menuItem of communitiesItems; let last = last" class="pl-4 pt-4">
                                        <a (click)="closeSideMenu()" href="{{menuItem.url}}" [target]="menuItem.urlTarget" [analyticTagging]="{'eventId':'secondaryNavDropDownClickFn','srcCtrl':['WORKSPACE',menuItem.shortName]}" class="menu-title font-medium text-px-14 cursor-pointer" [ngClass]="{'pb-4': last}">
                                            {{menuItem.title}}
                                    </a>
                                </div>
                            </ng-container>
                            <ng-template #otherSubCategories>
                                <div *ngFor="let menuItem of item.submenu; let last = last" class="pl-4 pt-4">
                                    <a *ngIf="buildUrl(menuItem) else notLink; let url" href="{{url}}"
                                    [target]="menuItem.target" (click)="closeSideMenu()" [analyticTagging]="{'eventId':'globalNavTrayDropDownExitLinkFn','srcCtrl':[item.title,menuItem.title]}" 
                                    class="menu-title font-medium text-px-14 cursor-pointer" [ngClass]="{'pb-4': last}">
                                        {{menuItem.title}}
                                </a>
                                <ng-template #notLink>
                                    <span class="menu-title font-medium text-px-14 cursor-pointer text-gray-500" disabled>
                                        {{menuItem.title}}
                                    </span>
                                </ng-template>
                            </div>
                            </ng-template>
                        </ng-template>
                    </div>
                  
                </ng-template>
            </div>
        </div>
        <div *ngIf="selectedItem.title === 'Notifications'">
            <app-header-sidenav-notifications></app-header-sidenav-notifications>
        </div>
        <div *ngIf="selectedItem.title === 'My Account'">
            <app-header-sidenav-my-account [user]="user" [yourAccountLink]="yourAccountLink"></app-header-sidenav-my-account>
        </div>
    </div>
</div>
