<div>
    <div id="notification-cont" class="pb-5" 
    [ngClass]="{'notification-container':notifications.length > 0 ,'no-notifications':notifications.length === 0 }">
        <div class="notificationLine" mat-menu-item *ngFor="let item of notifications">
            <div class="notification-message-width float-left"
                (click)="openNotificationDetailsDialog('Details', item)"
                [analyticTagging]="{'eventId':'notificationFlyout','srcCtrl':this}">
                <div class="notificationHeading text-base" [ngClass]="{'read-Heading': item.messageRead }">
                    <span class="dot" *ngIf="!item.messageRead" [ngClass]="{
                            'unplanned-dot' : item.messageType === 'UNPLANNED_OUTAGE',
                            'planned-dot' : item.messageType === 'PLANNED_OUTAGE',
                            'update-dot' : (item.messageType === 'USER_NOTIFICATION' || item.messageType === 'INFORMATIONAL'),
                            'training-dot' : (item.messageType === 'TRAINING'),
                            'action-req-dot' : (item.messageType === 'ACTION_REQUIRED')
                            }"></span> {{headerSidenavService.memoizeGetmessageType | getNotificationHeader:item.messageType }}
                </div>
                <div class="notificationTitle" [ngClass]="{'read-Title': item.messageRead }">
                    {{ item.messageTitle }}
                </div>
            </div>
        </div>
        <div class="no-notification flex justify-center" *ngIf="notifications.length === 0">
            <span class="no-notifications-font pt-24">No Active Notifications or Alerts</span>
        </div>
    </div>
    <div *ngIf="unreadMessages.length > 0" class="clearUpdates" (click)="clearUpdates()">
        <span class="mark-as-read-label">Mark All As Read</span>
    </div>
</div>
