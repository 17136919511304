<div>
    <div>
        <div class="superSub flex flex-col">
            <span *ngFor="let config of homePageConfig">
                <mat-checkbox  *ngIf="config.showOption === 'true'" [checked]="config.selected" (change)="checkCheckBoxvalue($event, config.configName)">
                    <span class="pl-6 fs12 color-grey" matTooltip="{{config.description}}" matTooltipClass="tooltip">
                        {{config.configName}}
                    </span>
                </mat-checkbox>
            </span>
        </div>
    </div>
</div>
