import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AppConfigService } from 'src/app/core/app-config.service';
import { FooterData, LinkCategory, Brands, SocialMedia, Link } from 'src/app/nxg-footer/nxg-footer-data/nxg-footer-data';
import { FooterService } from 'src/app/footer/footer.service';
import { Workspace } from 'src/app/workspace/workspace';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
import { Location } from '@angular/common';
import { UserApiService } from 'src/app/user/user-api.service';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { User } from 'src/app/user/user';

@Component({
  selector: 'app-nxg-footer',
  templateUrl: './nxg-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./nxg-footer.component.scss']
})
export class NxgFooterComponent implements OnInit {

  footerService: FooterService;
  appConfigService: AppConfigService;
  workspaceService: WorkspaceService;
  footerData: FooterData;
  linkCategories: LinkCategory[];
  portalLinks: LinkCategory[];
  shopAndDiscoverLinks: LinkCategory[];
  boeingLinks: LinkCategory[];
  mediaLinks: LinkCategory[];
  brands: Brands = {
    desktopHeading: '',
    mobileHeading: '',
    brandList: [] as SocialMedia[]
  } as Brands;
  socialMedia: SocialMedia[] = [] as SocialMedia[];
  socialMediaTitle = 'Follow Boeing';
  communityBase: string;
  mbfHome = false;
  envConfig: {};
  currYear = new Date().getFullYear();
  // show build, configuration in hidden div
  showInfoDiv = false;
  infoEnvProperties = ['shortServername', 'azureLocation', 'environment', 'portal-ng-buildTag', 'portal-ng-buildUser', 'portal-ng-buildTimestamp', 'portal-ng-gitBranch', 'portal-ng-gitCommit'];
  infoProperties = ['portal-ui-buildTag', 'portal-ui-buildUser', 'portal-ui-buildTimestamp', 'portal-ui-gitBranch', 'portal-ui-gitCommit'];

  nonTBCUser = false;
  accessAdministratorFlag = false;
  user = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[]
  } as User;
  // show build, configuration to specific users
  isEligibleUser = false;

  constructor(
    public cd: ChangeDetectorRef,
    footerService: FooterService,
    appConfigService: AppConfigService,
    public router: Router,
    public location: Location,
    workspaceService: WorkspaceService,
    protected userApiService: UserApiService
  ) {
    this.footerService = footerService;
    this.appConfigService = appConfigService;
    this.workspaceService = workspaceService;
    this.checkAccessAdminiPriviledge();
    this.userApiService.getUser().subscribe((user) => {
      this.user = user;
      if (!(user && user.airlineCode && user.airlineCode === 'TBC')) {
        this.nonTBCUser = true;
      }
      else{
        this.nonTBCUser = false;
      }
      this.cd.markForCheck();
    });
    if (this.user.userRoles.length > 0) {
      const findAdminRole = this.user.userRoles.find((item) => item.userRoleName === 'PORTAL ADMINISTRATOR');
      this.isEligibleUser = findAdminRole !== undefined;
    }
  }

  ngOnInit(): void {
    // get oemPlatformAcronym (MBF or MMF) from env_config,
    // and use that to get the appropriate footer data json
    this.appConfigService.envConfig.subscribe(config => {
      // tslint:disable-next-line: no-string-literal
      if (config['oemPlatformAcronym']) {
        this.envConfig = config;
        // tslint:disable-next-line: no-string-literal
        this.getFooterData(config['oemPlatformAcronym']);
      }
    });
    this.communityBase = `${this.appConfigService.getPortalUIPath()}/index.html#/Platform/workspace/`;
    this.setMbfHome();
    this.router.events.subscribe(val => {
      this.setMbfHome();
    });
    this.cd.markForCheck();
  }

  getFooterData(oemPlatformAcronym: string): void {
    // workspace data is from an endpoint separate from footer data
    // make requests in parallel and combine them when both return
    const footerDatas = this.footerService.getNxgGenFooterData(oemPlatformAcronym);
    const workspaces = this.workspaceService.getWorkspacesProp(true);
    this.cd.markForCheck();
    forkJoin([footerDatas, workspaces]).subscribe(results => {
      this.footerData = results[0];
      this.brands = this.footerData.brands;
      this.socialMedia = this.footerData.socialMedia;
     // this.linkCategories = this.footerData.links.linkCategories.filter((item) => item.title !== 'Communities');
      this.portalLinks = this.footerData.links.linkCategories.filter((item) =>
        item.title === 'Portals' || item.title === 'Other Resources' || item.title === 'MBF Support');
      this.shopAndDiscoverLinks = this.footerData.links.linkCategories.filter((item) => item.title === 'Shop' || item.title === 'Discover');
      this.boeingLinks = this.footerData.links.linkCategories.filter((item) => item.title === 'Boeing');
      this.mediaLinks = this.footerData.links.linkCategories.filter((item) => item.title === 'Stay Connected');
      this.portalLinks.forEach(item => {
        item.links.forEach(val => {
          if (val.type === 'rp'){
            // tslint:disable-next-line:no-string-literal
            val.url = this.envConfig['rpAlias'] + val.url;
          }
        });
      });
      this.shopAndDiscoverLinks.forEach(item => {
        item.links.forEach(val => {
          if (val.type === 'rp'){
            // tslint:disable-next-line:no-string-literal
            val.url = this.envConfig['rpAlias'] + val.url;
          }
        });
      });
      this.boeingLinks.forEach(item => {
        item.links.forEach(val => {
          if (val.type === 'rp'){
            // tslint:disable-next-line:no-string-literal
            val.url = this.envConfig['rpAlias'] + val.url;
          }
        });
      });
      this.mediaLinks.forEach(item => {
        item.links.forEach(val => {
          if (val.type === 'rp'){
            // tslint:disable-next-line:no-string-literal
            val.url = this.envConfig['rpAlias'] + val.url;
          }
        });
      });

      this.cd.markForCheck();
      // filter unlisted workspaces, transform to linkCategory type
      if (results && results[1] && Array.isArray(results[1].data)) {
        const wsr = results[1].data
          .filter((ws: Workspace) => ws.unlisted === false)
          .map(ws => this.buildWorkspaceLink(ws));
        if (wsr.length > 0) {
          for (const linkCategories of this.linkCategories){
            if (linkCategories.title === 'Portals'){
              for (const w of wsr){
                linkCategories.links.push(w);
              }
            }
          }
        }
      }
      this.cd.markForCheck();
    });
  }
  setMbfHome() {
    this.mbfHome = (this.location.path() === ''
      || this.location.path() === this.appConfigService.getRoute(`mbfHome`)
      || this.location.path() === this.appConfigService.getRoute(`mbfHomeWorkspace`));
  }
  toggleInfo(): void {
    this.showInfoDiv = !this.showInfoDiv;
  }

  getProperty(key: string) {
    return this.appConfigService.getProperty(key);
  }

  // format Workspace so we can display it as a link
  buildWorkspaceLink(ws: Workspace): Link {
    return { title: ws.longName, url: `${this.communityBase}${ws.id}`, urlTarget: '_self' };
  }

  buildMbfSupportPageUrl(type): string {
    let url = '';
    // tslint:disable-next-line: no-string-literal
    const mbfPspId = this.envConfig['mbfPspId'];
    switch (this.appConfigService.getProperty(`mbfSupportPageTarget`)) {
      case 'portal_ng':
        type = 'portal_ng';
        url = `index.html#/Platform/apps/${mbfPspId}`;
        break;
      case 'portal-ui':
      default:
        type = 'portal-ui';
        url = `#/Platform/psp/${mbfPspId}`;
        break;
    }
    this.cd.markForCheck();
    return url;
  }

  urlClicked(link) {
    const url: string = this.buildLink(link.url, link.type, link.title);
    if (url.toLowerCase().endsWith(window.location.hash.toLowerCase())) {
      window.scrollTo(0, 0);
    } else {
      window.open(url, link.urlTarget ? link.urlTarget : '_self');
    }
    this.cd.markForCheck();
    return false;
  }

  directUrlClicked(url, target) {
    if (url.toLowerCase().endsWith(window.location.hash.toLowerCase())) {
      window.scrollTo(0, 0);
    } else {
      window.open(url, target ? target : '_self');
    }
    this.cd.markForCheck();
    return false;
  }

  buildLink(url: string, type: string = 'external', title: string = ''): string {
    let updatedUrl;
    if (title === this.appConfigService.getProperty(`partpageTitle`)) {
      url = this.envConfig[`partpageUrl`];
    }
    if (title === this.appConfigService.getProperty(`mbfSupportPageTitle`)) {
      // tslint:disable-next-line:no-string-literal
      url = `#/Platform/psp/${this.envConfig['mbfPspId']}`;
    }
    if (title === 'Access Administrator Support') {
      url = this.buildAccessAdminLink();
    }
    if (title === 'Product and Data Access (Summary)') {
      url = this.buildAccessSummaryLink();
    }
    switch (type) {
      case 'portal_ng':
        updatedUrl = `${this.appConfigService.getPortalNgPath()}/${url}`;
        break;
      case 'rp':
        if (url.startsWith('/') || url === '') {
          updatedUrl = url;
        // tslint:disable-next-line: no-string-literal
        } else if (this.envConfig['isThroughRp']) {
          // tslint:disable-next-line: no-string-literal
          updatedUrl = `${this.envConfig['rpAlias']}${url}`;
        } else {
          updatedUrl = `/${url}`;
        }
        break;
      case 'external':
      default:
        updatedUrl = url;
        break;
    }
    this.cd.markForCheck();
    return updatedUrl;
  }
  buildAccessAdminLink() {
      let url = '';
      const accessAdminUrl = 'accessAdminUrl';
      const mbfAdminAccessId = this.envConfig[accessAdminUrl];
      url = `#/Platform/psp/${mbfAdminAccessId}`;
      return url;
  }
  buildAccessSummaryLink() {
    let url = '';
    const accessSummaryUrl = 'accessSummaryUrl';
    url = this.envConfig[accessSummaryUrl];
    return url;
  }

  getURL(url: string, urlTarget: string, type?: string, title?: string){
    const value = window.location.href;
    if (urlTarget === 'external') {
      urlTarget = '_blank';
    }
    if ( title === 'Access Administrator Support') {
      const updatedUrl = this.buildLink(url, type, title);
      window.open(updatedUrl, urlTarget);
    } else if ( title === 'Product and Data Access (Access Summary)') {
      const updatedUrl = this.buildLink(url, type, title);
      window.open(updatedUrl, urlTarget);
    }
    else {
      if (value.includes(url)) {
        window.scroll(0, 0);
        } else {
          if (title === 'My Field Service Office') {
            window.localStorage.setItem('fsoOpen', 'true');
          }
          const updatedUrl = this.buildLink(url, type, title);
          window.open(updatedUrl, urlTarget);
        }
    }
   }
   checkAccessAdminiPriviledge() {
     let envConfigAccessAdmin = '';
     const accessAdminUrl = 'accessAdminUrl';
     this.appConfigService.envConfig.subscribe(config => {
      if (config[accessAdminUrl]) {
        this.envConfig = config;
        envConfigAccessAdmin = config[accessAdminUrl];
        if (envConfigAccessAdmin === '') {
          this.accessAdministratorFlag = false;
        }
        else {
          this.accessAdministratorFlag = true;
        }
      }
      else {
        this.accessAdministratorFlag = false;
      }
    });
   }

}
